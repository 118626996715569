<template>
  <div class="create-edit-dialog-form">
    <!-- <v-row justify="center"> -->
    <v-bottom-sheet
      scrollable
      :persistent="persistent"
      :value="visible"
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
      @click:outside="persistent ? {} : closeDialog()"
    >
      <v-card>
        <v-card-title class="px-3 pt-2 pb-0" v-if="title">
          {{ title }}<v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle class="pb-0 pt-3 px-3" v-if="description">{{
          description
        }}</v-card-subtitle>
        <v-card-text class="px-3">
          <!-- IMPORTANT: Form -->
          <v-form
            @submit.prevent="sendObjectToServer()"
            ref="createEditForm"
            v-model="form"
          >
            <v-row dense class="mt-2">
              <!-- append something before form -->
              <slot name="before-form" v-bind="{ obj, fields }"></slot>
              <!-- core form with all dynamic fields -->
              <v-col
                v-for="(field, index) in Object.keys(fields).filter(
                  (f) => !(fields[f].hide || false)
                )"
                :key="index"
                cols="12"
                sm="12"
                :md="fields[field].md"
              >
                <!-- text field -->
                <v-text-field
                  v-if="fields[field].fieldType == 1 && !fields[field].hide"
                  :disabled="fields[field].disabled"
                  :required="fields[field].required || false"
                  :hint="fields[field].helper"
                  persistent-hint
                  outlined
                  :inputmode="fields[field].inputmode"
                  :type="fields[field].input || 'text'"
                  hide-details="auto"
                  :counter="fields[field].counter"
                  :messages="
                    fields[field].copyFrom &&
                    obj[field] != obj[fields[field].copyFrom]
                      ? fields[field].copyFromMessage
                      : null
                  "
                  :prepend-inner-icon="fields[field].prependIcon"
                  v-model="obj[field]"
                  :readonly="fields[field].readonly"
                  :maxlength="fields[field].max"
                  :rules="getFieldRules(field)"
                >
                  <template #label>
                    {{ fields[field].label
                    }}<span v-if="fields[field].required" class="red--text"
                      ><strong> * </strong></span
                    >
                  </template>
                  <template
                    v-if="fields[field].copyFrom"
                    v-slot:message="{ message }"
                  >
                    <v-chip
                      x-small
                      @click="obj[field] = obj[fields[field].copyFrom]"
                      v-if="
                        message == fields[field].copyFromMessage &&
                        fields[field].copyFrom &&
                        obj[field] != obj[fields[field].copyFrom]
                      "
                      outlined
                    >
                      {{ message }}
                    </v-chip>
                    <p v-else>{{ message }}</p>
                  </template>
                </v-text-field>
                <!-- text-area field -->
                <v-textarea
                  v-else-if="
                    fields[field].fieldType == 2 && !fields[field].hide
                  "
                  :disabled="fields[field].disabled"
                  :required="fields[field].required || false"
                  :hint="fields[field].helper"
                  persistent-hint
                  outlined
                  :rows="fields[field].rows || 4"
                  :type="fields[field].input || 'text'"
                  hide-details="auto"
                  :prepend-icon="fields[field].prependIcon"
                  v-model="obj[field]"
                  :maxlength="fields[field].max"
                  :rules="getFieldRules(field)"
                >
                  <template #label>
                    {{ fields[field].label
                    }}<span v-if="fields[field].required" class="red--text"
                      ><strong> * </strong></span
                    >
                  </template>
                </v-textarea>
                <!-- autocomplete field -->
                <v-autocomplete
                  v-else-if="
                    fields[field].fieldType == 3 &&
                    !fields[field].hide &&
                    fields[field].multiple
                  "
                  :ref="`input-field--${field}`"
                  v-model="obj[field]"
                  :items="selectionItems[field]"
                  small-chips
                  deletable-chips
                  :prepend-inner-icon="fields[field].prependIcon"
                  hide-details="auto"
                  persistent-hint
                  :hint="fields[field].helper"
                  :label="fields[field].label"
                  :item-text="fields[field].itemText"
                  :item-value="fields[field].itemValue"
                  multiple
                  outlined
                  @change="removeFocus(field)"
                  :rules="getFieldRules(field)"
                >
                  <template #label>
                    {{ fields[field].label
                    }}<span v-if="fields[field].required" class="red--text"
                      ><strong> * </strong></span
                    >
                  </template>
                </v-autocomplete>
                <!-- select field -->
                <v-select
                  v-else-if="
                    fields[field].fieldType == 3 &&
                    !fields[field].hide &&
                    !fields[field].multiple
                  "
                  v-model="obj[field]"
                  :items="selectionItems[field]"
                  persistent-hint
                  :hint="fields[field].helper"
                  :label="fields[field].label"
                  :item-text="fields[field].itemText"
                  :item-value="fields[field].itemValue"
                  :return-object="fields[field].returnObject || false"
                  :prepend-inner-icon="fields[field].prependIcon"
                  :clearable="fields[field].clearable"
                  :disabled="fields[field].disabled"
                  outlined
                  :rules="getFieldRules(field)"
                  hide-details="auto"
                >
                  <template #label>
                    {{ fields[field].label
                    }}<span v-if="fields[field].required" class="red--text"
                      ><strong> * </strong></span
                    >
                  </template>
                  <template v-slot:item="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item[fields[field].itemText]
                      }}</v-list-item-title>
                      <v-list-item-subtitle v-if="fields[field].itemSubtitle">{{
                        Helper.getProp(data.item, fields[field].itemSubtitle)
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="fields[field].getSubtitle">{{
                        fields[field].getSubtitle(data.item)
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-select>
                <!-- checkbox -->
                <v-checkbox
                  v-else-if="
                    fields[field].fieldType == 4 && !fields[field].hide
                  "
                  class="my-0"
                  :label="fields[field].label"
                  :hint="fields[field].helper"
                  persistent-hint
                  :type="fields[field].input || 'text'"
                  hide-details="auto"
                  :prepend-icon="fields[field].prependIcon"
                  v-model="obj[field]"
                  :value="fields[field].value"
                  :rules="getFieldRules(field)"
                />
                <!-- image picker -->
                <div
                v-else-if="
                    fields[field].fieldType == 5 && !fields[field].hide
                  ">
                  <v-file-input
                  v-show="false"
                  class="ma-0 pa-0"
                  :ref="`${field}_multiple`"
                  v-if="fields[field].multiple"
                  :multiple="true"
                  @change="(files) => {
                    pushFiles(files, field)
                  }"
                  accept="image/*"
                ></v-file-input>
                <v-file-input
                  v-show="false"
                  class="ma-0 pa-0"
                  :ref="`${field}_single`"
                  @change="(files) => {
                    pushFiles(files, field)
                  }"
                  accept="image/*"
                ></v-file-input>
                </div>
                
                <!-- single image picker -->
                <!-- <v-file-input
                  v-else-if="
                    fields[field].fieldType == 5.1 && !fields[field].hide
                  "
                  class="ma-0 pa-0"
                  :ref="field"
                  :multiple="false"
                  :label="fields[field].label"
                  :hint="fields[field].helper"
                  persistent-hint
                  outlined
                  prepend-inner-icon="mdi-attachment"
                  @change="(file) => obj[field] = file"
                  accept="image/*"
                >
                <template #label>
                    {{ fields[field].label
                    }}<span v-if="fields[field].required" class="red--text"
                      ><strong> * </strong></span
                    >
                  </template>
                </v-file-input> -->
                <!-- files picker -->

                <!-- Video upload -->
                <v-file-input
                  v-else-if="
                    fields[field].fieldType == 11 && !fields[field].hide
                  "
                  v-show="false"
                  class="ma-0 pa-0"
                  :ref="field"
                  :multiple="fields[field].multiple"
                  @change="(files) => pushFiles(files, field)"
                  accept="video/mp4, video/x-m4v, video/*"
                ></v-file-input>
                
                <v-file-input
                  v-else-if="
                    fields[field].fieldType == 6 && !fields[field].hide
                  "
                  v-show="false"
                  class="ma-0 pa-0"
                  :ref="field"
                  :multiple="fields[field].multiple"
                  @change="(files) => pushFiles(files, field)"
                ></v-file-input>
                <!-- datepicker -->
                <intract-date-time-picker
                  :ref="`intract-date-time-picker--${field}`"
                  v-if="
                    [8, 9, 10].includes(fields[field].fieldType) &&
                    !fields[field].hide
                  "
                  :label="fields[field].label"
                  :helper="fields[field].helper"
                  :picker-type="
                    fields[field].fieldType == 8
                      ? 1
                      : fields[field].fieldType == 9
                      ? 2
                      : 3
                  "
                  :format="fields[field].format"
                  :required="fields[field].required"
                  :value="obj[field]"
                  :min="fields[field].min"
                  :max="fields[field].max"
                  @valueChanged="(d) => (obj[field] = d)"
                  :custom-rules="fields[field].customRules"
                  :prepend-icon="fields[field].prependIcon"
                  :clearable="fields[field].clearable"
                />

                <!-- custom (slot) field -->
                <slot
                  v-if="fields[field].fieldType == 0 && !fields[field].hide"
                  :name="'custom-field--' + field"
                  v-bind="{ obj, fields }"
                ></slot>
              </v-col>
              <!-- append something after form -->
              <slot name="after-form" v-bind="{ obj, fields }"></slot>
              <small class="my-1 ml-2">*indicates required field</small>
              <!-- MEDIA SECTION -->
              <v-col cols="12">
                <v-btn
                  class="mt-2 non-focused"
                  v-if="enableMedia"
                  @click="showMediaOptions = true"
                  color="primary"
                  block
                  outlined
                  ><v-progress-circular
                    size="20"
                    class="mr-2"
                    indeterminate
                    v-if="isMediaLoading"
                  ></v-progress-circular
                  ><v-icon v-else class="mr-2">mdi-attachment</v-icon>Attach
                  Media</v-btn
                >
              </v-col>
              <!-- display all images -->
              <v-col
                cols="12"
                v-if="
                  fields[imageField] &&
                  obj[imageField] &&
                  (!fields[imageField].multiple || obj[imageField].length)
                "
              >
                <p class="body-2 mt-1 mb-0">{{ fields[imageField].label }}</p>
                <!-- single image needed -->
                <div v-if="!fields[imageField].multiple">
                  <v-card outlined class="my-1" elevation="0">
                    <v-list-item dense>
                      <v-list-item-icon class="mr-3"
                        ><div
                          :style="{
                            'background-image': obj[imageField].id
                              ? 'url(' + obj[imageField].image + ')'
                              : 'url(' + obj[imageField].document + ')',
                            width: '100%',
                            height: '100%',
                            'background-position': 'center',
                            'background-size': 'cover',
                          }"
                        />
                      </v-list-item-icon>
                      <v-list-item-title>{{
                        obj[imageField].file_name
                      }}</v-list-item-title>
                      <v-list-item-icon class="align-center"
                        ><v-btn icon @click="obj[imageField] = null"
                          ><v-icon>mdi-close</v-icon></v-btn
                        ></v-list-item-icon
                      >
                    </v-list-item>
                  </v-card>
                </div>
                <!-- multiple images needed -->

                <div v-else>
                  <v-card
                    outlined
                    class="my-1"
                    elevation="0"
                    v-for="(image, index) in obj[imageField]"
                    :key="index"
                  >
                    <v-list-item dense>
                      <v-list-item-icon class="mr-3"
                        ><div
                          :style="{
                            'background-image': image.id
                              ? 'url(' + image.image + ')'
                              : 'url(' + image.document + ')',
                            width: '100%',
                            height: '100%',
                            'background-position': 'center',
                            'background-size': 'cover',
                          }"
                        />
                      </v-list-item-icon>
                      <v-list-item-title>{{
                        image.file_name
                      }}</v-list-item-title>
                      <v-list-item-icon class="align-center"
                        ><v-btn
                          icon
                          @click="
                            obj[imageField] = obj[imageField].filter(
                              (i) => i != image
                            )
                          "
                          ><v-icon>mdi-close</v-icon></v-btn
                        ></v-list-item-icon
                      >
                    </v-list-item>
                  </v-card>
                </div>
              </v-col>

              <!-- display all documents -->
              <v-col
                cols="12"
                v-if="
                  fields[documentField] &&
                  obj[documentField] &&
                  obj[documentField].length
                "
              >
                <p class="body-2 mb-0">Documents</p>
                <v-card
                  outlined
                  class="my-1"
                  elevation="0"
                  v-for="(document, index) in obj[documentField]"
                  :key="index"
                >
                  <v-list-item dense>
                    <v-list-item-icon class="mr-3"
                      ><v-icon>mdi-attachment</v-icon></v-list-item-icon
                    >
                    <v-list-item-title>{{
                      document.file_name
                    }}</v-list-item-title>
                    <v-list-item-icon class="align-center"
                      ><v-btn
                        icon
                        @click="
                          obj[documentField] = obj[documentField].filter(
                            (bd) => bd != document
                          )
                        "
                        ><v-icon>mdi-close</v-icon></v-btn
                      ></v-list-item-icon
                    >
                  </v-list-item>
                </v-card>
              </v-col>

              <!-- display all videos -->
              <v-col
                cols="12"
                v-if="
                  fields[videoField] &&
                  obj[videoField] && (!fields[videoField].multiple || obj[videoField].length)
                "
              >
                <p class="body-2 mb-0">Video</p>
                <!-- single video needed -->
                <div v-if="!fields[videoField].multiple">
                  <v-card outlined class="my-1" elevation="0">
                    <v-list-item dense>
                      <v-list-item-icon class="mr-3"
                        ><v-icon>mdi-video</v-icon></v-list-item-icon
                      >
                      <v-list-item-title>{{
                        obj[videoField].file_name
                      }}</v-list-item-title>
                      <v-list-item-icon class="align-center"
                        ><v-btn icon @click="obj[videoField] = null"
                          ><v-icon>mdi-close</v-icon></v-btn
                        ></v-list-item-icon
                      >
                    </v-list-item>
                  </v-card>
                </div>
                <!-- multiple videos needed -->
                <div v-else>
                  <v-card
                    outlined
                    class="my-1"
                    elevation="0"
                    v-for="(video, index) in obj[videoField]"
                    :key="index"
                  >
                    <v-list-item dense>
                      <v-list-item-icon class="mr-3"
                        ><v-icon>mdi-video</v-icon></v-list-item-icon
                      >
                      <v-list-item-title>{{
                        video.file_name
                      }}</v-list-item-title>
                      <v-list-item-icon class="align-center"
                        ><v-btn 
                          icon 
                          @click="
                            obj[videoField] = obj[videoField].filter(
                              (i) => i != video
                            )
                          "
                          ><v-icon>mdi-close</v-icon></v-btn
                        ></v-list-item-icon
                      >
                    </v-list-item>
                  </v-card>
                </div>
              </v-col>

              <!-- display all links -->
              <v-col
                cols="12"
                v-if="
                  fields[linkField] &&
                  obj[linkField] &&
                  (!fields[linkField].multiple || obj[linkField].length)
                "
              >
                <p class="body-2 mt-1 mb-0">{{ fields[linkField].label }}</p>
                <div v-if="!fields[linkField].multiple">
                  <v-card outlined class="my-1" elevation="0">
                    <v-list-item dense>
                      <v-list-item-title>{{
                        obj[linkField].link
                      }}</v-list-item-title>
                      <v-list-item-icon class="align-center"
                        ><v-btn icon @click="obj[linkField] = null"
                          ><v-icon>mdi-close</v-icon></v-btn
                        ></v-list-item-icon
                      >
                    </v-list-item>
                  </v-card>
                </div>
                <div v-else>
                  <v-card
                    outlined
                    class="my-1"
                    elevation="0"
                    v-for="(video, index) in obj[linkField]"
                    :key="index"
                  >
                    <v-list-item dense>
                      <!-- <v-list-item-icon class="mr-3"
                      >
                      <div
                        :style="{
                          'background-image': video.id
                            ? 'url(' + image.image + ')'
                            : 'url(' + image.document + ')',
                          width: '100%',
                          height: '100%',
                          'background-position': 'center',
                          'background-size': 'cover',
                        }"
                      />
                    </v-list-item-icon> -->
                      <v-list-item-title>{{ video.link }}</v-list-item-title>
                      <v-list-item-icon class="align-center"
                        ><v-btn
                          icon
                          @click="
                            obj[linkField] = obj[linkField].filter(
                              (i) => i != video
                            )
                          "
                          ><v-icon>mdi-close</v-icon></v-btn
                        ></v-list-item-icon
                      >
                    </v-list-item>
                  </v-card>
                </div>
              </v-col>
              <!-- submission section -->
              <v-col cols="12" class="text-center">
                <p
                  class="red--text mb-1"
                  v-if="isLoading && enableMedia && uploadProgress"
                >
                  Files are being uploaded. Might take some time. Please, don't
                  close this.
                </p>
                <p class="red--text mb-1" v-if="customErrorText">
                  {{ customErrorText }}
                </p>
                <v-progress-linear
                  :value="uploadProgress"
                  v-if="uploadProgress"
                  color="primary"
                  background-color="#f0f7f7"
                ></v-progress-linear>
                <v-btn
                  class="mt-0"
                  type="submit"
                  :disabled="
                    isLoading ||
                    !form ||
                    !allFieldsValid ||
                    customErrorText != null
                  "
                  color="primary"
                  block
                  >{{ submitButtonText }}</v-btn
                >
              </v-col>
              <v-col>
                <!-- append something after form -->
                <slot name="after-submit-button" v-bind="{ obj, fields }"></slot>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <!-- media choice selector -->
    <intract-list-sheet
      title="What do you want to upload?"
      :visible="showMediaOptions"
      :list="mediaOptions"
      @close="showMediaOptions = false"
    />

    <!-- media attach options selector (camera or files) -->
    <intract-list-sheet
      title="How do you want to upload?"
      :visible="showAttachOptions"
      :list="attachOptions"
      @close="showAttachOptions = false"
    />
    <!-- link picker -->
    <v-bottom-sheet
      v-if="linkField"
      v-model="showLinkInputSheet"
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
    >
      <v-card>
        <v-card-title class="px-3">Link a YouTube or Google Drive Video</v-card-title>
        <v-card-subtitle class="pb-0 pt-1 px-3"
          >Go to <a target="_blank" href="https://youtube.com">YouTube</a> >
          Copy Link of a Video > Paste in this field</v-card-subtitle
        >
        <v-card-subtitle class="pb-2 pt-1 px-3"
          >OR
          <a target="_blank" href="https://youtube.com/upload"
            >Upload a Video on YouTube</a
          >
          > Copy Link of the uploaded Video > Paste in this
          field</v-card-subtitle
        >

        <v-card-subtitle class="pb-0 pt-1 px-3"
          >OR
          <a target="_blank" href="https://drive.google.com"
            >Upload a Video on Google Drive</a
          >
          > Copy Link of the uploaded Video > Paste in this
          field. <p>  Please make sure the access is set to “Anyone with the link”. </p></v-card-subtitle
        >

        <v-card-subtitle class="px-3">OR Add a Custom Link Video. 
         </v-card-subtitle>
        
        <v-card-text class="px-3">
          <v-form
            @submit.prevent="attachVideoLink(videoLink)"
            v-model="videoLinkForm"
            ref="video-link-form"
          >
            <v-text-field
              v-model="videoLink"
              :rules="[
                (v) => !!v || 'This field is required',
                // old regex
                //(v) =>
                //  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
                //    v
                //  ) || 'Invalid Video Link',
                (v) => /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/.test(v) || /https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?\?usp=(.*?)/g.test(v) || 'Invalid video link',

              ]"
              hide-details="auto"
              :hint="fields[linkField].helper"
              outlined
            >            
              <template #label>
                {{ fields[linkField].label
                }}<span v-if="fields[linkField].required" class="red--text"
                  ><strong> * </strong></span
                >
              </template>
            </v-text-field>

            <v-checkbox
              v-if="videoLink && !this.videoLinkForm"
              v-model="customLinkCheckbox"
              label="This is a Custom Video Link"
            ></v-checkbox>

            <v-btn
              class="mt-3"
              :disabled="!videoLinkForm && !customLinkCheckbox"
              type="submit"
              color="primary"
              block
              >Add Video</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <confirmation-dialog
          :visible="showMultipleImageConfirmDialog"
          title="Multiple images"
          description="Please note: Select atleast 2 photos with this option, some devies may not support selection of single image in the multiple selection option. If you want to select a single image, please select 'Images (Single)'"
          @successCallback="openMultiImageSelectInput"
          @failureCallback="() => {
            showMultipleImageConfirmDialog = false;
            multiImageField = null
          }"
        />
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import api from "@api/index";
import { mapActions, mapGetters } from "vuex";
import IntractListSheet from "@components/generics/IntractListSheet";
import Helper from "@utils/misc";
import IntractDateTimePicker from "@components/generics/IntractDateTimePicker";
import ConfirmationDialog from '@components/dialogs/ConfirmationDialog';

export const CreateEditFieldTypes = Object.freeze({
  CUSTOMFIELD: 0,
  TEXTFIELD: 1,
  TEXTAREA: 2,
  SELECT: 3,
  CHECKBOX: 4,
  IMAGEUPLOAD: 5,
  SINGLEIMAGEUPLOAD: 5.1,
  FILEUPLOAD: 6,
  LINKUPLOAD: 7,
  DATEPICKER: 8,
  TIMEPICKER: 9,
  DATETIMEPICKER: 10,
  VIDEOUPLOAD: 11,
});

export default {
  name: "IntractCreateEditSheet",

  components: {
    IntractListSheet,
    IntractDateTimePicker,
    ConfirmationDialog
  },

  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default() {
        return {};
      },
    },
    endpoint: {
      type: String,
      default: "",
    },
    dataObject: {
      type: Object,
      default() {
        return {};
      },
    },
    editId: {
      type: Number,
      default: null,
    },
    createSuccessMessage: {
      type: String,
      default: "Successfully saved!",
    },
    editSuccessMessage: {
      type: String,
      default: "Successfully saved!",
    },
    enableMedia: {
      type: Boolean,
      default: false,
    },
    fileSizeLimit: {
      type: Number,
      default: null,
    },
    submitButtonText: {
      type: String,
      default: "Submit",
    },
    getObject: {
      type: Function,
      default: async (o) => o,
    },
    afterEditObjectReceived: {
      type: Function,
      default: async (o) => o,
    },
    customErrorText: {
      type: String,
      default: null,
    },
    customSendObjectFunction: {
      type: Function,
      default: null,
    },
    partialUpdate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Helper,
      moment,
      obj: {},
      initialObj: null,
      objectId: null,
      form: false,
      selectionItems: {},
      showMultipleImageConfirmDialog: false,
      multiImageField: null,
      rules: {
        required: (v) => !!v || v === 0 || "Required",
        requiredList: (v) => !v || v.length > 0 || "Required",
        numbersOnly: (v) => !v || !/[^\-0-9.]/.test(v) || "Invalid",
      },

      showMediaOptions: false,
      showLinkInputSheet: false,
      showAttachOptions: false,
      attachmentField: null,
      videoLinkForm: false,
      isMediaLoading: false,
      videoLink: null,
      uploadProgress: null,
      deviceInfo: null,

      // pickers
      datePickerMenu: false,
      customLinkCheckbox: false,
    };
  },

  computed: {
    ...mapGetters(["accessToken", "isLoading"]),
    essentials() {
      return {
        accessToken: this.accessToken,
        setLoading: this.setLoading,
        handleErrorsFunction: this.handleApiErrors,
      };
    },

    allFieldsValid() {
      var fieldsValid = true;
      Object.keys(this.fields).forEach((f) => {
        var fieldData = this.fields[f];
        if (fieldData.valid && !fieldData.hide) {
          if (!fieldData.valid()) fieldsValid = false;
        }
      });
      return fieldsValid;
    },
    imageField() {
      return Object.keys(this.fields).find(
        (f) => this.fields[f].fieldType == 5
      );
    },

    documentField() {
      return Object.keys(this.fields).find(
        (f) => this.fields[f].fieldType == 6
      );
    },

    videoField() {
      return Object.keys(this.fields).find(
        (f) => this.fields[f].fieldType == 11
      );
    },

    linkField() {
      return Object.keys(this.fields).find(
        (f) => this.fields[f].fieldType == 7
      );
    },
    mediaOptions() {
      if (!this.enableMedia) return [];
      var options = [];
      var cameraField = Object.keys(this.fields).find(
        (f) =>
          (this.fields[f].fieldType == 5 || this.fields[f].fieldType == 6) &&
          this.fields[f].camera
      );

      if (
        cameraField in this.fields &&
        this.deviceInfo &&
        this.deviceInfo.platform != "web"
      )
        options.push({
          icon: "mdi-camera",
          title: "Use Camera",
          subtitle: "Use your camera to click photo(s)",
          action: () => this.openCamera(cameraField),
        });

      var imageField = Object.keys(this.fields).find(
        (f) => this.fields[f].fieldType == 5
      );
      if (imageField in this.fields)
        options.push({
          icon: "mdi-image",
          title: this.fields[imageField].label + ' (Single)',
          subtitle: this.fields[imageField].helper,
          action: () => this.attachImages(imageField, false),
        });
        if(this.fields[imageField]?.multiple){
          options.push({
          icon: "mdi-image-multiple",
          title: this.fields[imageField].label + ' (Multiple)',
          subtitle: this.fields[imageField].helper,
          action: () => this.attachImages(imageField,true),
        });
        }
      var fileField = Object.keys(this.fields).find(
        (f) => this.fields[f].fieldType == 6
      );
      if (fileField in this.fields)
        options.push({
          icon: "mdi-attachment",
          title: this.fields[fileField].label,
          subtitle: this.fields[fileField].helper,
          action: () => this.attachDocuments(fileField),
        });
      var videoField = Object.keys(this.fields).find(
        (f) => this.fields[f].fieldType == 11
      );
      if (videoField in this.fields)
        options.push({
          icon: "mdi-video",
          title: this.fields[videoField].label,
          subtitle: this.fields[videoField].helper,
          action: () => this.attachDocuments(videoField),
        });
      var linkField = Object.keys(this.fields).find(
        (f) => this.fields[f].fieldType == 7
      );
      if (linkField in this.fields)
        options.push({
          icon: "mdi-link",
          title: this.fields[linkField].label,
          subtitle: this.fields[linkField].helper,
          action: () => (this.showLinkInputSheet = true),
        });

      return options;
    },
    attachOptions() {
      console.log(this.attachmentField);
      return [
        {
          icon: "mdi-camera-enhance",
          title: "Camera",
          action: () => {
            this.openCamera();

            this.attachmentField = null;
          },
        },
        {
          icon: "mdi-file-image",
          title: "Files",
          action: () => {
            // if (!this.attachmentField) return;
            if (this.fields[this.attachmentField].fieldType == 5)
              this.attachImages(this.attachmentField);
            else if (this.fields[this.attachmentField].fieldType == 6)
              this.attachDocuments(this.attachmentField);
            this.attachmentField = null;
          },
        },
      ];
    },
  },

  methods: {
    ...mapActions(["setLoading", "showSnackbar"]),

    async removeFocus(field) {
      this.$refs["input-field--" + field][0].blur();
    },

    closeDialog() {
      // clear all values then close
      this.$emit("close");
      this.resetDateTimePickers();
      this.$refs.createEditForm.resetValidation();
      this.obj = null;
      this.objectId = null;
      this.initObjectFields();
      // this.obj = null;
    },

    async resetDateTimePickers() {
      Object.keys(this.fields).map((field) => {
        if ([8, 9, 10].includes(this.fields[field].fieldType)) {
          //reset if picker field found
          var refName = "intract-date-time-picker--" + field;
          if (this.$refs[refName]) this.$refs[refName][0].resetData();
        }
      });
    },

    // async displayAttachOptions(field) {
    //   if (this.deviceInfo.platform == "web") {
    //     if (this.fields[field].fieldType == 5) this.attachImages(field);
    //     else if (this.fields[field].fieldType == 6) this.attachDocuments(field);
    //     return;
    //   }
    //   this.showAttachOptions = true;
    //   this.attachmentField = field;
    // },

    async openCamera(cameraField) {
      const getCamera = () => import("@capacitor/camera");
      const Camera = (await getCamera()).Camera;
      const CameraResultType = (await getCamera()).CameraResultType;

      if (this.deviceInfo.platform == "web") {
        return;
      }

      try {
        const cameraPicture = await Camera.getPhoto({
          quality: 70,
          allowEditing: false,
          resultType: CameraResultType.Uri,
          source: "CAMERA",
        });
        var self = this;
        var isMultiple = this.fields[cameraField].multiple;
        fetch(cameraPicture.webPath)
          .then((r) => r.blob())
          .then((blob) => {
            console.log(blob);
            blob.name = `camera_${moment().format()}.${cameraPicture.format}`;
            self.pushFiles(isMultiple ? [blob] : blob, cameraField);
          });
      } catch (error) {
        console.error(error);
      }
    },
    async attachImages(imageField, multiple=true) {
      if(multiple){
        this.multiImageField = imageField
        this.showMultipleImageConfirmDialog = true;
      }else{
        this.$refs[`${imageField}_single`][0].$refs.input.click();
      }
      this.showMediaOptions = false;      
    },

    openMultiImageSelectInput() {
      this.showMultipleImageConfirmDialog = false
      this.$refs[`${this.multiImageField}_multiple`][0].$refs.input.click();
    },

    async attachDocuments(documentField) {
      this.$refs[documentField][0].$refs.input.value = null;
      this.$refs[documentField][0].$refs.input.click();
      this.showMediaOptions = false;
    },
    
    async attachVideoLink(link) {
      this.$refs["video-link-form"].reset();
      if (this.fields[this.linkField].multiple) {
        if (this.obj[this.linkField] == null) this.obj[this.linkField] = [];
        this.obj[this.linkField].push({ link: link });
      } else {
        this.obj[this.linkField] = { link: link };
      }

      this.showLinkInputSheet = false;
    },

    // getBase64Size(base64) {
    //   // Get the size of the base64 string
    //   /*
    //              Reference: https://www.jb51.net/article/172316.htm
    //              And optimized the code
    // */
    //   if (base64) {
    //     // Get the byte size of base64 image
    //     base64 = base64.split(",")[1].split("=")[0];
    //     var strLength = base64.length;
    //     var fileLength = strLength - (strLength / 8) * 2;
    //     return Math.floor(fileLength); // round down
    //   } else {
    //     return 0;
    //   }
    // },

    // async checkFileSizeLimit() {
    //   if(!this.enableMedia) return true;
    //   var self = this;
    //   if (!this.fileSizeLimit) return true;
    //   var totalImageSize = 0;
    //   if (this.fields[this.imageField].multiple) {
    //     this.obj[this.imageField].map(
    //       (image) => (totalImageSize += self.getBase64Size(image.document))
    //     );
    //     console.log(totalImageSize);
    //     if (totalImageSize > this.fileSizeLimit)
    //       this.showSnackbar({
    //         text: `Total size of images cannot be more than ${(
    //           this.fileSizeLimit /
    //           1024 /
    //           1024
    //         ).toFixed(2)}MB. You are trying to upload ${(
    //           totalImageSize /
    //           1024 /
    //           1024
    //         ).toFixed(2)}MB.`,
    //         type: "error",
    //       });
    //     return false;
    //   }
    //   // var totalSize = 0;
    //   // files.map((f) => (totalSize += f.size));
    //   // if (totalSize > this.fileSizeLimit) {

    //   //   return false;
    //   // }
    //   return true;
    // },

    async pushFiles(files, field) {
      if (!files) return;
      if (this.fields[field].multiple) {
        var pushedFiles
        if (Array.isArray(files)){
          pushedFiles = files
        }else{
          pushedFiles = [files]
        }
        pushedFiles.map(async (file) => {
          if (this.obj[field] == null) this.$set(this.obj, field, []);
          this.isMediaLoading = true;
          var image_object = {
            document: await Helper.toBase64(file),
            file_name: file.name,
          };

          this.isMediaLoading = false;

          this.obj[field].push(image_object);
        });
      } else {
        // only one file expected
        if (this.obj[field] == null) this.$set(this.obj, field, null);
        this.isMediaLoading = true;
        var image_object = {
          document: await Helper.toBase64(files),
          file_name: files.name,
        };

        this.isMediaLoading = false;
        this.obj[field] = image_object;
      }
    },

    getFieldRules(field) {
      var fieldRules = [];
      var fieldData = this.fields[field];
      var rules = fieldData.rules ?? [];
      var customRules = fieldData.customRules ?? [];
      fieldRules = rules.map((r) => this.rules[r]);
      if (fieldData.pattern)
        fieldRules.push((v) => fieldData.pattern.test(v) || "Invalid");
      if (fieldData.required ?? false) {
        if (fieldData.multiple && fieldData.fieldType == 3)
          fieldRules.push(this.rules["requiredList"]);
        // for v-autcomplete
        else fieldRules.push(this.rules["required"]);
      }
      if (customRules.length) fieldRules.push(...customRules);
      return fieldRules;
    },

    async sendObjectToServer() {
      // if (!(await this.checkFileSizeLimit())) return;
      // only send fields that are visible
      if (this.customSendObjectFunction) {
        this.customSendObjectFunction(this.obj, this.closeDialog);
        return;
      }
      var objToSend = await this.getObject(this.obj);
      var url = this.objectId
        ? this.endpoint + this.objectId + "/"
        : this.endpoint;
      var response = await api.call(
        this.essentials,
        url,
        this.objectId ? this.partialUpdate ? api.Methods.PATCH : api.Methods.PUT : api.Methods.POST,
        objToSend,
        (p) => {
          // handle upload progress
          var totalLength = p.lengthComputable
            ? p.total
            : p.target.getResponseHeader("content-length") ||
              p.target.getResponseHeader("x-decompressed-content-length");
          var progress = Math.round((p.loaded * 100) / totalLength);
          this.uploadProgress = progress;
        }
      );
      if (response) {
        this.uploadProgress = null;
        this.showSnackbar({
          title: this.editId
            ? this.editSuccessMessage
            : this.createSuccessMessage,
          type: "success",
        });
        this.$emit("objectCreated", response);
        this.closeDialog();
      }
    },

    async handleApiErrors(err) {
      if (this.$listeners && this.$listeners.apiError) {
        this.$emit("apiError", err);
        return;
      }
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.showSnackbar({
          title: "Something went wrong",
          type: "error",
        });
      }
    },

    async getEditObject() {
      var response = await api.call(
        this.essentials,
        this.endpoint + this.objectId + "/"
      );
      console.log("initial edit response", response)

      Object.keys(this.fields).map((f) => {
        this.obj[f] = response[f];

        // extra code added to handle itemValue and return-object case. if itemValue, then all server response items in object should be of itemValue
        if (this.fields[f].fieldType == 3 && this.fields[f].itemValue) {
          // if field is of select type and it also contains itemValue attribute
          if (this.fields[f].multiple)
            this.obj[f] = this.obj[f].map(
              // iterate over server-returned objects
              (item) => item[this.fields[f].itemValue] // only get that specific attribute from objects of the list
            );
          else if (this.obj[f][this.fields[f].itemValue])
            this.obj[f] = this.obj[f][this.fields[f].itemValue];
        }
      });
      this.obj = await this.afterEditObjectReceived(this.obj, response);
    },

    async getSelectionItems() {
      Object.keys(this.fields).map(async (f) => {
        // get items for multiselect fields based on endpoint
        var fieldData = this.fields[f];
        if (fieldData.fieldType == 3) {
          if (fieldData.endpoint) {
            this.selectionItems[f] = await api.call(
              this.essentials,
              fieldData.endpoint
            );
          } else {
            this.selectionItems[f] = fieldData.items;
          }
        }
      });
    },

    async initObjectFields() {
      this.obj = this.initialObj;
      Object.keys(this.fields).map(async (f) => {
        var fieldData = this.fields[f];
        Vue.set(this.obj, f, fieldData.value);
      });
    },

    async getDeviceInfo() {
      const getDevice = () => import("@capacitor/device");
      const Device = (await getDevice()).Device;
      this.deviceInfo = await Device.getInfo();
    },
  },

  async mounted() {
    this.initObjectFields();
    // console.log("edit ID:", this.objectId);
  },

  async created() {
    this.getDeviceInfo();
    this.initialObj = this.dataObject;
    this.getSelectionItems();
  },

  watch: {
    editId: {
      async handler() {
        this.objectId = this.editId;
        if (this.objectId) this.getEditObject();
      },
    },
    obj: {
      async handler() {
        this.$emit("updateObject", this.obj);
      },
      deep: true,
    },
    // fields: {
    //   async handler() {
    //     this.getSelectionItems();
    //   },
    //   deep: true,
    // },
  },
};
</script>
