<template>
  <div class="intract-date-time-picker">
    <!-- <v-menu
      @input="datePickerMenu = true"
      ref="datePickerMenu"
      v-model="datePickerMenu"
      :close-on-content-click="false"
      transition="slide-y-transition"
      offset-y
      min-width="auto"
    > -->
    <v-dialog
      ref="datePickerMenu"
      v-model="datePickerMenu"
      :return-value.sync="dateModel"
      width="290px"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{}">
        <v-text-field
          @click="handleTextFieldClick"
          v-model="formattedValueString"
          :label="label"
          :hint="helper"
          persistent-hint
          :prepend-inner-icon="prependIcon"
          outlined
          :rules="[...(required ? [rules.required] : []), ...customRules]"
          hide-details="auto"
          aria-autocomplete="off"
          readonly
          :clearable="clearable"
          @click:clear="dateObject = timeObject = dateTimeObject = dateModel = timeModel = null; $emit('valueChanged', null)"
        >
          <template #label>
            {{ label
            }}<span v-if="required" class="red--text"
              ><strong> * </strong></span
            >
          </template></v-text-field
        >
      </template>
      <v-date-picker
        v-model="dateModel"
        :min="getISODateFormat(min)"
        :max="getISODateFormat(max)"
        no-title
        @input="onDateInput"
      ></v-date-picker>
      <!-- </v-menu> -->
    </v-dialog>
    <v-dialog
      ref="timePickerMenu"
      v-model="timePickerMenu"
      transition="slide-y-transition"
      width="290px"
    >
      <v-time-picker
        v-if="timePickerMenu"
        v-model="timeModel"
      >
      <v-spacer></v-spacer>
        <v-btn text color="primary" class="non-focused" @click="timePickerMenu = false">Cancel</v-btn>
        <v-btn text color="primary" :disabled="timeModel == null" @click="onTimeInput">OK</v-btn>
      </v-time-picker>
      
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";

function initialState() {
  return {
    formattedValueString: null,
    dateTimeObject: null,
    dateObject: null,
    timeObject: null,
    dateModel: null,
    timeModel: null,
    datePickerMenu: false,
    timePickerMenu: false,
    moment: moment,
    rules: {
      required: (v) => !!v || v === 0 || "Required",
    },
  };
}
export default {
  name: "IntractDateTimePicker",
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
      required: true,
    },
    helper: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Date,
      default: null,
    },
    max: {
      type: Date,
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    pickerType: {
      type: Number,
      default: 1,
      // 1 - DatePicker, 2 - TimePicker, 3 - DateTimePicker
    },
    customRules: {
      type: Array,
      default: () => [],
    },
    format: {
      type: String,
      default: null,
    },
  },
  data: () => initialState(),
  methods: {
    // checks the Picker Type and decides which picker to open
    handleTextFieldClick() {
      if (this.pickerType == 1 || this.pickerType == 3)
        this.datePickerMenu = true;
      else if (this.pickerType == 2) this.timePickerMenu = true;
    },
    onDateInput() {
      this.$refs.datePickerMenu.save(this.dateModel);
      this.datePickerMenu = false;
      this.handleFormatting(this.dateModel, this.timeModel);
      if (this.pickerType == 1) {
        // date picker: should close after dateinput and emit date
        this.emitValue();
        return;
      }
      this.timePickerMenu = true;
    },
    onTimeInput() {
      this.$refs.timePickerMenu.save(this.timeModel);
      this.timePickerMenu = false;
      this.handleFormatting(this.dateModel, this.timeModel);
      this.emitValue();
    },

    resetData() {
      Object.assign(this.$data, initialState());
    },
    handleFormatting(date, time) {
      if (this.pickerType == 1) this.formatDate(date);
      else if (this.pickerType == 2) this.formatTime(time);
      else this.formatDateTime(date, time);
    },
    formatDate(date) {
      this.dateObject = moment(date, "YYYY-MM-DD");
      this.formattedValueString = this.dateObject.format(
        this.format ?? "D MMM, YYYY"
      );
    },
    formatTime(time) {
      this.timeObject = moment(time, "HH:mm:ss");
      this.formattedValueString = this.timeObject.format(
        this.format ?? "h:mmA"
      );
    },
    formatDateTime(date, time) {
      if (!time) return;
      this.dateTimeObject = moment(date + time, "YYYY-MM-DDhh:mm");
      this.formattedValueString = this.dateTimeObject.format(
        this.format ?? "D MMM, YYYY h:mmA"
      );
    },

    getISODateFormat(date) {
      if (!date) return null;
      return moment(date).format();
    },

    emitValue() {
      var value;
      if (this.pickerType == 1) value = this.dateObject.format("YYYY-MM-DD");
      else if (this.pickerType == 2) value = this.timeObject.format("HH:mm:ss");
      else value = this.dateTimeObject.format();
      this.$emit("valueChanged", value);
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.value) return;
        var momentInstance;
        if (this.pickerType == 1 || this.pickerType == 3) {
          momentInstance = moment(this.value);
          this.dateModel = momentInstance.format("YYYY-MM-DD");
        } else momentInstance = moment(this.value, "HH:mm:ss");
        this.timeModel = momentInstance.format("H:mm");
        this.handleFormatting(this.dateModel, this.timeModel);
      },
      immediate: true,
    },
  },
};
</script>